
.hr-text {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 1.5em;
    opacity: 0.7;
    margin: 10px 20px;
  }
  .hr-text:before {
    content: "";
    background:  #a6a4a8;
    position: absolute;
    left: 0;
    top: 50%;
    color: black;
    width: 100%;
    height: 1px;
  }
  .hr-text:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: rgb(15, 15, 15)!important;
    padding: 0 0.5em;
    font-size: 18px!important;
    line-height: 1.5em;
    background-color: #fcfcfa;
  }

.leaveCard{
    padding: 30px 0;
    /* margin: 0 20px; */
    height: 100%;
    opacity: 1;
    box-shadow: 0 1px 3px 0px rgba(180, 180, 180, .4);
    background-color: white;
}


.handled{
    padding: 30px 0;
    opacity: 0;
    box-shadow: 0 1px 3px 0px rgba(180, 180, 180, .4);
    animation: shutdown 1s ease-in-out 
}

@keyframes shutdown {
    0%, 5%   { transform: scale(1, 1); opacity: 1; }
    35%       { transform: scale(1.2, 0.03); opacity: 0.8; }
    45%, 100% { transform: scale(0, 0); opacity: 0.3; }
}
